/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { DeletedProfileLogo } from 'assets';
import { HUHU_HOMEPAGE_URL } from 'configs';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './DeletedProfile.scss';

const DeletedProfile: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.deleted'
  });

  return (
    <Container
      fluid
      className="py-6 text-center d-flex justify-content-center align-items-center bg-white deleted-profile"
    >
      <Row className="gap-4">
        <Col xs="12">
          <DeletedProfileLogo width="100%" />
        </Col>
        <Col xs="12" className="message">
          <Trans t={t} i18nKey="message" />
        </Col>
        <Col xs="12">
          <Link
            to={HUHU_HOMEPAGE_URL}
            className="btn btn-primary btn-lg"
            replace
          >
            {t('toLandingPage')}
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default DeletedProfile;
