/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const chooseOrganizationPage = {
  navigateTo: 'Zurück zur Lösung {{solution}}',
  help: 'Wähle eine bestehende Organisation oder erstelle eine neue, um sie zu {{solution}} hinzuzufügen. Dank der zentralen Verwaltung über dein huhu Konto musst du deine Organisation nur einmal erstellen und kannst sie einfach zu verschiedenen Diensten hinzufügen.',
  createAndAdd: 'Erstellen und zu {{solution}} hinzufügen',
  create: {
    title: 'Organisation erstellen',
    description: 'Du bist noch in keiner Organisation.',
    createDescription:
      'Erstelle eine neue Organisation auf der huhu Plattform. Mitglieder und das Profil der Organisation können zentral für alle Lösungen in deinem huhu Konto verwaltet werden.'
  },
  select: {
    title: 'Organisation wählen',
    help: 'Wähle eine bestehende Organisation oder erstelle eine neue, um sie zu DorfFunk hinzuzufügen. Dank der zentralen Verwaltung über dein huhu Konto musst du deine Organisation nur einmal erstellen und kannst sie einfach zu verschiedenen Diensten hinzufügen.',
    description:
      'Wähle oder erstelle eine Organisation, um sie zu {{solution}} hinzuzufügen.',
    search: 'Organisation suchen',
    organizationLogoAlt: 'Logo der Organization {{organization}}',
    create: 'Neue Organisation erstellen',
    add: 'Zu {{solution}} hinzufügen'
  },
  error: 'Bitte überprüfe die Url-Parameter.'
};
