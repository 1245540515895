/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRMediaItem, SLRMediaSizes, getIcon } from '@SLR/shared-library';
import { Organization } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_ORGANIZATION_IMAGE_PATH } from 'configs';
import { FC, useState } from 'react';
import { Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ExistingOrganizationListProps = {
  defaultSearchValue?: string;
  organizations?: Organization[];
  onSelect: (organizationId: string) => void;
  selectedOrganizationId?: string;
};

const ExistingOrganizationList: FC<ExistingOrganizationListProps> = ({
  defaultSearchValue,
  organizations,
  onSelect,
  selectedOrganizationId
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chooseOrganizationPage.select'
  });

  const [searchValue, setSearchValue] = useState(defaultSearchValue ?? '');

  const filteredOrganizations = organizations?.filter((o) =>
    o.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Row>
      <Col>
        <InputGroup>
          <InputGroup.Text>
            <Form.Label
              htmlFor="search"
              className="m-0"
              aria-label={t('search')}
              title={t('search')}
            >
              <FontAwesomeIcon icon={getIcon('far', 'search')} />
            </Form.Label>
          </InputGroup.Text>
          <Form.Control
            id="search"
            type="text"
            placeholder={t('search')}
            className="form-control-lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>

        <ListGroup className="mt-4 organization-list" variant="flush">
          {filteredOrganizations?.map((organization) => {
            const selected = selectedOrganizationId === organization.id;
            return (
              <ListGroup.Item
                key={organization.id}
                onClick={() => onSelect(organization.id)}
                active={selected}
                className="item py-3 d-flex align-items-center justify-content-between"
              >
                <SLRMediaItem
                  mediaSize={SLRMediaSizes.Thumbnail}
                  mediaItemId={organization.logoId}
                  altFallback={t('organizationLogoAlt', {
                    organization: organization.name
                  })}
                  roundedCircle
                  errorFallback={DEFAULT_ORGANIZATION_IMAGE_PATH}
                  className="image"
                />
                <span className="ms-2 flex-fill">
                  <Form.Label htmlFor={organization.name} className="name">
                    {organization.name}
                  </Form.Label>
                </span>
                <span className="text-end">
                  <Form.Check
                    id={organization.name}
                    type="radio"
                    name="organization"
                    className="d-flex align-items-center"
                    value={organization.id}
                    checked={selected}
                    readOnly={true}
                    autoComplete="false"
                  />
                </span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default ExistingOrganizationList;
